import React from "react"
import { PageProps } from "gatsby"
import Layout from '../components/Layout/Layout'
import SEO from '../components/seo'

const NotFoundPage: React.FC<PageProps> = ({ location }) => {
  return (
    <Layout location={location}>
      <SEO />
      404 Not Found
    </Layout>
  )
}

export default NotFoundPage
